import styled from "styled-components";

export const LoadingWrapper = styled.div`
	position: fixed;
	width: 100%;
	height: 100vh;
	overflow: hidden !important;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #F7F7F7;
	left: 0;
	top: 0;
`

export const LoadingContainer = styled.div`
	height: 100%;
	position: relative;
	bottom: env(safe-area-inset-bottom);
	top: 0;
	display: flex;
	gap: 10px;
	align-items: center;

	@keyframes fadeIn {
		from {
			opacity: 0;
			transform: translateY(20px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}

	img {
		opacity: 0;
		animation: fadeIn 1s forwards;
	}

	img:nth-child(1) {
		animation-delay: 0.15s;
	}
	img:nth-child(2) {
		animation-delay: 0.3s;
	}
	img:nth-child(3) {
		animation-delay: 0.45s;
	}
	img:nth-child(4) {
		animation-delay: 0.6s;
	}
	img:nth-child(5) {
		animation-delay: 0.75s;
	}
	img:nth-child(6) {
		animation-delay: 0.9s;
	}
	
	@media screen and (min-width: 400px)  and (max-width: 560px){
		img{
			height: 50px;
		}
	}
	@media screen and (max-width: 400px){
		img{
			height: 42px;
		}
	}
`