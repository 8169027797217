import { Section, ProjectContainer } from "./styles";

import projects from "../../../../Assets/Lang/en/work/projects.json";
import { useMobileDetector } from "../../../../utils/customHooks";

const AllSuggested = ({ handleOpenPopup }) => {
	const ismobile = useMobileDetector();

	return (
		<Section>
			{Object.keys(projects).map((project, index) => (
				<ProjectContainer
					type={projects[project].outer.type}
					key={index}
					onClick={() => {
						handleOpenPopup(true, project);
					}}
					ismobile={ismobile}
				>
					<div>
						<img
							src={require(
								`../../../../Assets/Images/Work/${projects[project].outer.img}`,
							)}
							alt={projects[project].outer.title}
						/>
					</div>

					<div>
						<span>{projects[project].outer.title}</span>
						<p>{projects[project].outer.description}</p>
					</div>
				</ProjectContainer>
			))}
		</Section>
	);
};

export default AllSuggested;
