import { useEffect, useState } from "react";

import AllSuggested from "../../Components/Organisms/Work/AllSuggested";
import Reputation from "../../Components/Organisms/Home/ReputationSwiper";
import ShareVision from "../../Components/Organisms/Services/ShareVision";
import ProjectsPopup from "../../Components/Molecules/Work/ProjectsPopup";
import HeroSection from "../../Components/Organisms/Work/HeroSection";
import Outcomes from "../../Components/Organisms/Work/Outcomes";

const WorkPage = () => {
	const [popupInfo, setPopupInfo] = useState({
		isOpened: false,
		project: "",
	});

	const handleOpenPopup = (bool, projectName) => {
		setPopupInfo({
			isOpened: bool ? bool : !popupInfo.isOpened,
			project: popupInfo.project ? "" : projectName,
		});
	};

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		window.scrollTo({
	// 			top: 0,
	// 			behavior: "smooth"
	// 		});
	// 	}, 100);
	// }, []);

	return (
		<div>
			<HeroSection />
			<Outcomes />
			<AllSuggested handleOpenPopup={handleOpenPopup} />
			<Reputation />
			<ShareVision id="shareVisionSection" />
			{popupInfo.isOpened && (
				<ProjectsPopup
					handleClose={() => handleOpenPopup(false)}
					info={popupInfo}
				/>
			)}
		</div>
	);
};

export default WorkPage;
