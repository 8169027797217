import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
	HomePage,
	ServicesPage,
	CulturePage,
	WorkPage,
	ContactPage,
} from "./Pages";
import DefaultLayout from "./Layouts/Default";

const Routing = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<DefaultLayout />}>
					<Route path="/" element={<HomePage />} />
					<Route path="/services" element={<ServicesPage />} />
					<Route path="/work" element={<WorkPage />} />
					<Route path="/culture" element={<CulturePage />} />
					<Route path="/contact-us" element={<ContactPage />} />
					<Route exact path="*" element={<Navigate to="/" />}></Route>
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default Routing;
