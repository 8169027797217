import { PopupServices } from "./styles";

const Services = ({ servicesObj }) => {

	return (
		<PopupServices>
			<div>
				<span>Client</span>
				<p>{servicesObj.client}</p>
			</div>
			<div>
				<span>Services</span>
				<p dangerouslySetInnerHTML={{ __html: servicesObj.services }} />
			</div>
			<div>
				<span>Sector</span>
				<p dangerouslySetInnerHTML={{ __html: servicesObj.sector }} />
			</div>
		</PopupServices>
	);
};

export default Services;
