import styled from "styled-components";

export const PopupPictures = styled.div`
	width: 100%;
	margin-top: calc(40px + 5vw);
	display: flex;
	flex-flow: row wrap;
	gap: 40px;
	box-sizing: border-box;
	
	@media (max-width: 480px) {
		margin-top: 60px;
        gap: 24px;
    }
	
	@media (min-width: 1600px) {
        margin-top: 120px;
	}
`;

export const Picture = styled.div`
	width: ${(props) => (props.type === "small" ? "calc(50% - 20px)" : "100%")};

	img {
		width: 100%;
	}

    @media (max-width: 480px) {
		width: 100%;
    }
`;
