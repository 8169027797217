import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

export const Section = styled.section`
	width: 100%;
	position: relative;
	left: 0;
	right: 0;
	margin: 140px auto 0 auto;

    @media (max-width: 768px) {
        margin-top: 60px;
    }
`;

export const SectionHeading = styled.h2`
	text-align: center;
`;

export const TextRow = styled.span`
	font-family: Inter, sans-serif;
	font-weight: 500;
	font-size: calc(24px + 1.5vw);
    line-height: calc(32px + 1.631vw);
	display: block;
	text-transform: uppercase;
	
	@media (max-width: 480px) {
        font-size: 32px;
        line-height: 38.73px;
	}

	@media (min-width: 1600px) {
		font-size: 48px;
		line-height: 58.09px;
	}
`;

export const ReputationRow = styled.div`
	width: calc(100% + 200px);
	margin-top: calc(30px + 3.125vw);
	margin-left: -100px;
	display: flex;
	
	&:hover {
		cursor: pointer;
	}
	
	@media (max-width: 768px) {
		width: calc(100% + 32px);
		margin-left: -16px;
	}
`;

export const ReputationImage = styled.img`
	display: block;
	width: ${(props) => (!props.ismobile ? "100%" : "auto")};
	height: auto;

	@media (min-width: 1600px) {
		width: 452px;
	}
`;

export const CustomSwiperSlide = styled(SwiperSlide)`
	max-width: 452px;
	width: calc(100% - 32px) !important;
	margin: 0 16px;
`;
