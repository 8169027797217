import {
	Section,
	SectionHeading,
	TextRow,
	StepWrapper,
	StepContainer,
	StepInfo,
	StepButtonWrapper,
} from "./styles";

import step1 from "../../../../Assets/Images/Services/step1.webp";
import step2 from "../../../../Assets/Images/Services/step2.webp";
import step3 from "../../../../Assets/Images/Services/step3.webp";
import step4 from "../../../../Assets/Images/Services/step4.webp";
import buttonArrow from "../../../../Assets/Images/Home/footer-arrow.svg";

const steps = [
	{
		img: step1,
		title: "Initiate the conversation",
		description: `To discuss your project, schedule a call or fill out the inquiry form on our website here.<br /><br />
       We'll send you right away an NDA and Discovery Questionnaire to ensure confidentiality while we gather insights for our initial discussion.`,
	},
	{
		img: step2,
		title: "Discovery leadership session",
		description:
			"Following your inquiry, we invite you to a strategy session with our C-level decision-makers. In this candid exchange, we delve into your business aspirations, dissect the nuances of your project, and align on the trajectory for success.",
	},
	{
		img: step3,
		title: "Tailored proposal creation",
		description:
			"Equipped with insights from our discussion, we craft a comprehensive proposal that details the project’s scope, a bespoke roadmap, timelines, and a transparent cost structure.",
	},
	{
		img: step4,
		title: "Seamless onboarding experience",
		description: `Upon alignment, we formalize our partnership through a Master Services Agreement, marking the beginning of our journey. <br /><br />
       Our onboarding process is meticulous, aimed at integrating our efforts with your internal teams smoothly.`,
	},
];

const Steps = () => {
	return (
		<Section>
			<SectionHeading>
				<TextRow>OUR FIRST FEW</TextRow>
				<TextRow>STEPS TOGETHER</TextRow>
			</SectionHeading>

			<StepWrapper>
				{steps.map((step, index) => (
					<StepContainer key={index}>
						{index % 2 === 0 && (
							<img src={step.img} alt={step.title} />
						)}
						<StepInfo>
							<span>{step.title}</span>
							<p
								dangerouslySetInnerHTML={{
									__html: step.description,
								}}
							/>
						</StepInfo>
						{index % 2 === 1 && (
							<img src={step.img} alt={step.title} />
						)}
					</StepContainer>
				))}
			</StepWrapper>

			<StepButtonWrapper>
				<button>
					Let’s talk and discuss your project{" "}
					<img src={buttonArrow} alt="" />
				</button>
			</StepButtonWrapper>
		</Section>
	);
};

export default Steps;
