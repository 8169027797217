import styled from "styled-components";
import { SwiperSlide } from "swiper/react";

export const Section = styled.section`
	flex: 1;
	width: 100%;
	box-sizing: border-box;
	padding-top: 60px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media (max-width: 768px) {
		align-items: flex-start;
	}
`;

export const SectionHeading = styled.div`
	width: 100vw;
	height: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	text-transform: uppercase;

	span {
		font-size: calc(52.5px + 9.844vw);
		font-weight: 500;
		line-height: calc(63.5px + 11.912vw);
		text-align: left;
	}

	& > div > div {
		transition-timing-function: linear !important;
	}

	@media (min-width: 480px) and (max-width: 992px) {
		span {
			font-size: calc(24px + 9.844vw);
			line-height: calc(29px + 11.912vw);
		}
	}

	@media (max-width: 768px) {
		margin-left: -16px;
	}

	@media (max-width: 480px) {
		span {
			font-size: calc(12px + 9.844vw);
			line-height: calc(29px + 11.912vw);
		}
	}

	@media (min-width: 1600px) {
		span {
			font-size: 210px;
			line-height: 254.15px;
		}
	}
`;

export const CustomSwiperSlide = styled(SwiperSlide)`
	color: ${(props) => (props.gray ? "#BDBDBD" : "#000000")};
`;

export const ContactsContainer = styled.div`
	margin-top: calc(20px + 3.125vw);
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: calc(8px + 6.8vw);

	@media screen and (min-width: 1600px) {
		gap: 117px;
		margin-top: 70px;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		gap: 20px;
	}
`;

export const Contacts = styled.div`
	max-width: 175px;

	h6 {
		margin: 0;
		font-size: calc(8px + 1.25vw);
		font-weight: 500;
		line-height: calc(8px + 1.5vw);
		letter-spacing: 0.02em;
		color: #000000;
	}

	p {
		margin-top: 20px;
		font-size: calc(8px + 0.75vw);
		font-weight: 400;
		line-height: calc(8px + 1vw);
		letter-spacing: 0.02em;
		text-align: left;
		color: #565656;
	}

	a {
		margin-top: 24px;
		font-size: calc(8px + 0.75vw);
		font-weight: 400;
		line-height: calc(8px + 1vw);
		letter-spacing: 0.02em;
		color: #000000;
	}

	@media screen and (max-width: 768px) {
		h6 {
			margin: 0;
			font-size: 16px;
			line-height: 19px;
		}

		p {
			margin: 10px 0 0;
			font-size: 12px;
			line-height: 14px;
		}

		a {
			margin: 10px 0 0;
			font-size: 12px;
			line-height: 14px;
		}
	}

	@media screen and (min-width: 1600px) {
		h6 {
			font-size: 28px;
			line-height: 33.89px;
		}

		p {
			font-size: 20px;
			line-height: 24.2px;
		}

		a {
			font-size: 20px;
			line-height: 24.2px;
		}
	}
`;
