import styled from "styled-components";
import { Link } from "react-router-dom";

export const Navbar = styled.nav`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    top: ${(props) =>
            props.visible ? "0" : "-100%"}; /* Hide navbar when not visible */
    left: 0;
    transition: top 0.5s ease-in-out; /* Add transition for top property */
    margin-top: ${(props) => props.visible && !props.attop && (props.ismobile ? "0" : "20px")};
    z-index: 2;
	
	// for the Home HeroSection arrow
	@media (max-width: 1300px) {
		z-index: 111;
    }

    & > div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        max-width: ${(props) => (props.attop ? "100%" : "1000px")};
        transition: 0.5s ease-in-out;
        background-color: ${(props) =>
                props.attop
                        ? "transparent"
                        : props.visible && (props.ismobile ? "#FFFFFF" : "rgba(255, 255, 255, 0.6)")};
        padding: ${(props) => props.attop ? "49.5px" : "29.5px"} 60px;
        border-radius: ${(props) => !props.ismobile && "90px"};
        backdrop-filter: ${(props) => !props.attop && !props.ismobile && "blur(10px)"};
        z-index: 111;
    }

    & > div > div:first-child {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    @media (max-width: 768px) {
        & > div {
            padding: 26px 16px;
        }
    }
`;

export const NavLinkMobile = styled.div`
	display: none;
	cursor: pointer;

	@media (max-width: 992px) {
		width: 20px;
		height: 12px;
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		gap: 4px;

		div,
		&:before,
		&:after {
			content: "";
			width: 100%;
			border: 1px solid
				${(props) =>
					props.loc === "/"
						? props.attop
							? "#FFFFFF"
							: "#000000"
						: "#1F1F1F"};
		}
	}
`;

export const NavLinkContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	gap: calc(2px + 1.875vw);

	@media (max-width: 992px) {
		display: none;
	}

	@media (min-width: 1600px) {
		gap: 32px;
	}
`;

export const Logo = styled.img`
	cursor: pointer;
	height: calc(12px + 1vw);
	
	@media (min-width: 1600px) {
		height: 28px;
	}
`;
export const NavLink = styled(Link)`
	font-size: calc(10px + 0.625vw);
	font-weight: 400;
	line-height: calc(12px + 0.762vw);
	letter-spacing: 0.02em;
	text-align: left;
	color: ${(props) =>
		props.loc === "/" ? (props.attop ? "#FFFFFF" : "#000000") : "#000000"};
	text-decoration: none;
	display: flex;
	align-items: center;
	border-bottom: ${(props) => props.current && "2px solid #000000"};

	@media (min-width: 1600px) {
		font-size: 20px;
		line-height: 24.2px;
	}
`;

export const ContactLink = styled(Link)`
	font-size: calc(10px + 0.625vw);
	font-weight: 400;
	line-height: calc(12px + 0.762vw);
	letter-spacing: 0.02em;
	color: ${(props) =>
		props.loc === "/" ? (props.attop ? "#FFFFFF" : "#000000") : "#000000"};

	@media (min-width: 1600px) {
		font-size: 20px;
		line-height: 24.2px;
	}
`;
