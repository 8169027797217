import {
	Section,
	SectionHeading,
	TextRow,
	ReputationRow,
	ReputationImage,
	CustomSwiperSlide,
} from "./styles";

import reputationImg1 from "../../../../Assets/Images/Home/reputationSwiper1.webp";
import reputationImg2 from "../../../../Assets/Images/Home/reputationSwiper2.webp";
import reputationImg3 from "../../../../Assets/Images/Home/reputationSwiper3.webp";

import { FreeMode, Autoplay } from "swiper/modules";
import { Swiper } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { useMobileDetector } from "../../../../utils/customHooks";

const ReputationSwiper = () => {
	const ismobile = useMobileDetector();

	return (
		<Section>
			<SectionHeading>
				{ismobile ? (<>
					<TextRow>Reputation is</TextRow>
					<TextRow>everything so</TextRow>
					<TextRow>ours is flawless</TextRow>
				</>) : (<>
					<TextRow>Reputation is everything</TextRow>
					<TextRow>so ours is flawless</TextRow>
				</>)}

			</SectionHeading>

			<ReputationRow ismobile={ismobile ? 1 : 0}>
				<Swiper
					loop={true}
					autoplay={{
						delay: 5000,
						disableOnInteraction: false,
						pauseOnMouseEnter: true,
					}}
					modules={[FreeMode, Autoplay]}
					spaceBetween={0}
					slidesPerView="auto"
					breakpoints={{
						0: {
							slidesPerView: 1,
						},
						480: {
							slidesPerView: 2,
						},
						992: {
							slidesPerView: 3,
						},
						1392: {
							slidesPerView: 3.265,
						},
						1600: {
							slidesPerView: "auto",
						},
					}}
				>
					<CustomSwiperSlide>
						<ReputationImage src={reputationImg1} alt="" />
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<ReputationImage src={reputationImg2} alt="" />
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<ReputationImage src={reputationImg3} alt="" />
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<ReputationImage src={reputationImg2} alt="" />
					</CustomSwiperSlide>
					<CustomSwiperSlide>
						<ReputationImage src={reputationImg3} alt="" />
					</CustomSwiperSlide>
				</Swiper>
			</ReputationRow>
		</Section>
	);
};

export default ReputationSwiper;
