import { useEffect, useState } from "react";
import {
	FonderAvatarContainer,
	FonderContainer,
	FonderReviewBox,
	Section,
	FounderAvatar,
	TextRow,
	SectionHeading,
	FonderNameContainer,
} from "./styles";

import fonderAvatar from "../../../../Assets/Images/Culture/fonderAvatar.webp";
import { useMobileDetector } from "../../../../utils/customHooks";

const OurDigitalHeaven = () => {
	const ismobile = useMobileDetector();
	const [showMore, setShowMore] = useState(false);
	const [initialDescription, setInitialDescription] = useState("");
	const [remainingDescription, setRemainingDescription] = useState("");

	const descriptionParagraphs =
		"In January 2020, three visionaries came together with one shared goal: to build a digital IT company that would shape the future of technology. Each of the founding leaders brought a unique skill set and a relentless passion for innovation, and from their collaboration, Spesna was born. <br/><br/>The journey began with a simple idea: to help businesses thrive in the digital world by providing high-quality, custom digital solutions. With a firm belief in the power of technology and design, the founders spent countless days and nights refining their vision, perfecting their processes, and ensuring every detail of their services was crafted with precision.<br/><br/>Together, these three leaders combined their expertise in design, development, and marketing, turning Spesna into a dynamic digital powerhouse. Despite launching in early 2020 amidst the challenges of a global pandemic, their unwavering dedication and hard work allowed the company to rise and thrive. From a small, ambitious team, Spesna grew into a thriving IT firm that pushed the boundaries of what was possible in the digital world.";

	useEffect(() => {
		if (descriptionParagraphs) {
			const words = descriptionParagraphs.split(" ");
			const initial = words.slice(0, 47).join(" ");
			const remaining = words.slice(47).join(" ");
			setInitialDescription(initial);
			setRemainingDescription(remaining);
		}
	}, [descriptionParagraphs]);

	const toggleShowMore = () => {
		setShowMore(!showMore);
	};

	return (
		<Section>
			<SectionHeading>
				{ismobile ? (
					<>
						<div>
							<TextRow>Welcome to</TextRow>
						</div>
						<div>
							<TextRow>our Digital</TextRow>
						</div>
						<div>
							<TextRow>heaven</TextRow>
						</div>
					</>
				) : (
					<>
						<div>
							<TextRow>Welcome to our</TextRow>
						</div>
						<div>
							<TextRow>Digital heaven</TextRow>
						</div>
					</>
				)}
			</SectionHeading>
			<FonderContainer>
				<FonderReviewBox>
					{ismobile ? (
						<p>
							<span
								dangerouslySetInnerHTML={{
									__html:
										initialDescription +
										(showMore
											? " " + remainingDescription
											: ""),
								}}
							></span>
							{!showMore && remainingDescription && (
								<span onClick={toggleShowMore}>
									read more
								</span>
							)}
							{showMore && (
								<span onClick={toggleShowMore}> read less</span>
							)}
						</p>
					) : (
						<p
							dangerouslySetInnerHTML={{
								__html: descriptionParagraphs,
							}}
						></p>
					)}
					<FonderAvatarContainer>
						<FounderAvatar
							src={fonderAvatar}
							alt={"fonderAvatar"}
						/>
						<FonderNameContainer>
							<h5>Fonder & CEO</h5>
							<h6>Of Spesna</h6>
						</FonderNameContainer>
					</FonderAvatarContainer>
				</FonderReviewBox>
			</FonderContainer>
		</Section>
	);
};

export default OurDigitalHeaven;
