import { Section, ServiceContainer, ServiceInfo, ServiceRow } from "./styles";

import allSuggested1 from "../../../../Assets/Images/Services/allSuggested1.svg";
import allSuggested2 from "../../../../Assets/Images/Services/allSuggested2.svg";
import allSuggested3 from "../../../../Assets/Images/Services/allSuggested3.svg";
import allSuggested4 from "../../../../Assets/Images/Services/allSuggested4.svg";
import allSuggested5 from "../../../../Assets/Images/Services/allSuggested5.svg";
import allSuggested6 from "../../../../Assets/Images/Services/allSuggested6.svg";
import allSuggested7 from "../../../../Assets/Images/Services/allSuggested7.svg";
import allSuggested8 from "../../../../Assets/Images/Services/allSuggested8.svg";
import allSuggested9 from "../../../../Assets/Images/Services/allSuggested9.svg";
import allSuggested10 from "../../../../Assets/Images/Services/allSuggested10.svg";

const services = [
	[
		{
			img: allSuggested1,
			title: "IOS | Android development",
			description:
				"We use React Native to create outstanding native mobile experiences with a single codebase for both iOS and Android, reducing development time and effort for faster releases.",
		},
		{
			img: allSuggested2,
			title: "Desktop app development",
			description:
				"We develop Windows, MacOS and Linux based apps using the Electron technology. This cross-platform devil is used to make desktop users happy.",
		},
	],
	[
		{
			img: allSuggested3,
			title: "Web development",
			description:
				"We execute both Frontend and Backend (API) development services. The team focuses on Javascript technologies, therefore 9/10 projects are done using the same tech stack: React + Node.js.",
		},
		{
			img: allSuggested4,
			title: "MVP development services",
			description:
				"We can build an MVP in under 4 months with a limited budget. Tell us what you want — we’ll deliver a product that users love.",
		},
	],
	[
		{
			img: allSuggested5,
			title: "UI/UX design",
			description:
				"We prototype, come up with branding, build custom UI/UX Design for web, mobile and desktop platforms. Conduct UX interviews to make sure the design is 100% user-friendly.",
		},
		{
			img: allSuggested6,
			title: "Graphic Design",
			description:
				"We create impactful branding and logo designs tailored to your audience. Through market research and UX interviews, Whether launching or refreshing a brand, we deliver strategic, appealing designs.",
		},
	],
	[
		{
			img: allSuggested7,
			title: "Quality Assurance",
			description:
				"Our QA specialists take control over revealing all possible bugs by forecasting unbelievable user behaviors, writing test cases and comprehensive manual testing after each development iteration.",
		},
		{
			img: allSuggested8,
			title: "Project management",
			description:
				"The project management team ensures successful project delivery. By 'successful' we understand a) in time, b) on budget c) sharp performance d) absolute transparency in the process.",
		},
	],
	[
		{
			img: allSuggested9,
			title: "Marketing",
			description:
				"Need a new team member? We’ve got 200 of them. Pick a specialist with just the right stack and waste no time on paperwork — our IT outstaffing services will take care of all the bureaucracy and HR worries.",
		},
		{
			img: allSuggested10,
			title: "IT Outsourcing",
			description:
				"We’ll help you develop a solid IT-product from scratch. Just tell us your idea and we’ll provide the necessary IT outsourcing services — you won’t have to hire and maintain staff to get a working MVP.",
		},
	],
];

const AllSuggested = () => {
	return (
		<Section>
			{services.map((row, rowIndex) => (
				<ServiceRow key={rowIndex}>
					{row.map((service, serviceIndex) => (
						<ServiceContainer key={serviceIndex}>
							<div>
								<img src={service.img} alt={service.title} />
							</div>
							<ServiceInfo>
								<span>{service.title}</span>
								<p>{service.description}</p>
							</ServiceInfo>
						</ServiceContainer>
					))}
				</ServiceRow>
			))}
		</Section>
	);
};

export default AllSuggested;
