import styled from "styled-components";

export const PopupServices = styled.div`
	width: 100%;
	display: flex;
	gap: calc(14px + 7.813vw);
	justify-content: space-between;

	div {
		width: 23.4285%;
        display: flex;
		flex-direction: column;
	}

	span {
		font-family: Inter, sans-serif;
		font-size: calc(12px + 0.75vw);
		font-weight: 500;
		line-height: calc(16px + 0.816vw);
		text-align: left;
		color: rgba(153, 153, 153, 1);
	}

	p > span {
		font-family: Inter, sans-serif;
		font-size: calc(8px + 0.5vw);
		font-weight: 400;
		line-height: calc(12px + 0.46vw);
		letter-spacing: 0.02em;
		text-align: left;
		color: rgba(0, 0, 0, 1);
	}

	@media (max-width: 480px) {
		span {
            font-size: 12px;
            line-height: 14.52px;
        }
		
		p {
            font-size: 11px;
            line-height: 13px;
			margin: 0;
        }
	}

	@media (min-width: 1600px) {
		gap: 139px;

		div {
			width: 23.4285%;
		}

		span {
			font-size: 24px;
			line-height: 29.05px;
		}

		p {
			font-size: 16px;
			line-height: 19.36px;
		}
	}
`;
