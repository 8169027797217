import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	position: relative;
	left: 0;
	right: 0;
	margin: 120px auto 0;
	max-width: 1400px;
	display: flex;
	flex-direction: row;
	gap: calc(20px + 1.25vw);
	flex-flow: row wrap; /* Allow items to wrap into multiple lines */
	box-sizing: border-box;
	
	@media (max-width: 768px) {
		margin: 80px auto 0;
    }

	@media (min-width: 1600px) {
		gap: 40px;
	}
`;

export const ProjectContainer = styled.div`
    cursor: pointer;
    width: ${(props) => (props.type === "small" ? "calc(50% - 20px)" : "100%")};
    display: flex;
    flex-direction: column;
    gap: calc(10px + 0.625vw);
    flex-flow: column;
	transition: 0.5s ease-in-out;
	
	&:hover {
		img	{
			transform: ${(props) => (!props.ismobile && "scale(1.2)")};
		}
	}
	
	div:first-child {
		background-color: transparent;
		position: relative;
		overflow: hidden;
		border-radius: 60px;
	}

    img {
        width: 100%;
		height: 100%;
        transition: 0.5s ease-in-out;
		transform: ${(props) => (props.ismobile && "scale(1.2)")};
    }

    span {
        font-size: calc(12px + 0.75vw);
        font-weight: 500;
        line-height: calc(16px + 0.816vw);
        text-align: left;
    }

    p {
        font-size: calc(8px + 0.5vw);
        font-weight: 400;
        line-height: calc(8px + 0.71vw);
        letter-spacing: 0.02em;
        text-align: left;
    }

    @media (max-width: 768px) {
        width: 100%;
		align-items: center;

        div:first-child {
            border-radius: 30px;
            width: 100%;
            height: 592px;
			max-width: 680px;
        }
		
		img {
			object-fit: cover;
		}

        div:nth-child(2) {
			width: 100%;
		}
    }

    @media (max-width: 640px) {
        div:first-child {
            height: 248px;
        }
    }

    @media (min-width: 320px) and (max-width: 480px) {
        span {
            font-size: calc(14px + 0.75vw);
            line-height: calc(18px + 0.816vw);
        }

        p {
            font-size: calc(10px + 0.5vw);
            line-height: calc(10px + 0.71vw);
        }
    }

    @media (max-width: 320px) {
        span {
            font-size: 20px;
            line-height: 24.2px;
        }

        p {
            font-size: 12px;
            line-height: 14.52px;
        }
    }

    @media (min-width: 1600px) {
        gap: 20px;

        span {
            font-size: 24px;
            line-height: 29.05px;
        }

        p {
            font-size: 16px;
            line-height: 19.36px;
        }
    }
`;
