import {
	Section,
	SectionHeading,
	TextRow,
	QuestionsWrapper,
	QuestionContainer,
	QuestionTitle,
} from "./styles";
import { useMobileDetector } from "../../../../utils/customHooks";

const questions = [
	{
		title: "What services does Spesna Team offer?",
		content:
			"We specialize in web and mobile development, UI/UX design, QA engineering and testing, " +
			"SEO, and marketing. Our diverse team can handle a wide range of projects to meet your digital needs."
	},
	{
		title: "What makes Spesna Team's UI/UX design unique?",
		content:
			"Our UI/UX design focuses on creating user-friendly interfaces that enhance the user experience. " +
			"We utilize extensive research and testing to ensure our designs meet the needs and expectations of your target audience."
	},
	{
		title: "How does Spesna Team approach web and mobile development?",
		content:
			"We take a comprehensive approach to web and mobile development, ensuring that each project is optimized " +
			"for performance, security, and scalability. Our team stays updated with the latest technologies and " +
			"best practices to deliver high-quality solutions."
	},
	{
		title: "What is included in our QA engineering and testing services?",
		content:
			"Our QA engineering and testing services include functional testing, performance testing, " +
			"security testing, and usability testing. We ensure your product is reliable, secure, and provides a seamless user experience."
	},
];

const FAQ = () => {
	const ismobile = useMobileDetector();

	return (
		<Section>
			<SectionHeading>
				{ismobile ? (<>
					<TextRow>You have</TextRow>
					<TextRow>questions</TextRow>
					<TextRow>We have</TextRow>
					<TextRow>answers</TextRow>
				</>) : (<>
					<TextRow>You have</TextRow>
					<TextRow>questions</TextRow>
					<TextRow>We have answers</TextRow>
				</>)}

			</SectionHeading>

			<QuestionsWrapper>
				{questions.map((question, index) => (
					<QuestionContainer key={index} open={index === 0}>
						<QuestionTitle>{question.title}</QuestionTitle>
						<p>{question.content}</p>
					</QuestionContainer>
				))}
			</QuestionsWrapper>
		</Section>
	);
};

export default FAQ;
