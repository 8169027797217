import { useEffect, useState, useRef } from "react";
import axios from "axios";

export function useMobileDetector() {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);
	return isMobile;
}

export function useSendEmail(url) {
	const [validationErrors, setValidationErrors] = useState({});
	const formRefs = {
		fullName: useRef(null),
		email: useRef(null),
		tel: useRef(null),
		company: useRef(null),
		siteURL: useRef(null),
		message: useRef(null),
	};

	const isValidEmail = (email) => {
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	const isValidUrl = (url) => {
		const pattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
		return pattern.test(url);
	}

	const validate = (formData) => {
		let newErrors = {};

		if (formData.fullName && formData.fullName.split("")[0] === " ") newErrors.fullName = "Name is invalid"
		if (formData.email && !isValidEmail(formData.email)) newErrors.email = "Email is invalid";
		if (formData.message && formData.message.length < 10) newErrors.message = "Message is too short";
		if (formData.siteURL && !isValidUrl(formData.siteURL)) newErrors.siteURL = "URL is invalid";

		if (!formData.fullName) newErrors.fullName = "Name is required";
		if (!formData.email) newErrors.email = "Email is required";
		if (formData.fromContacts && !formData.message) newErrors.message = "Message is required";

		setValidationErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const sendEmail = async (formData) => {
		if (validate(formData)) {
			try {
				const response = await axios.post(url, formData);
				console.log('Email sent successfully:', response);
				if (response.data.msg === "SUCCESS") {
					// Clear form fields
					for (let key in formRefs) {
						if (formRefs[key].current) {
							formRefs[key].current.value = "";
						}
					}
					setValidationErrors({});  // Clear any previous validation errors
					return response.data.msg;
				}
			} catch (error) {
				console.error('Error sending email:', error);
				setValidationErrors({ general: 'Error sending email' });
				return "ERROR";
			}
		}
	};

	return { sendEmail, validationErrors, setValidationErrors, formRefs };
}