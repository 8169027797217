import React, { useState, useEffect, lazy, Suspense } from "react";

import heroSectionWebP from "../../Assets/Images/Home/heroSection.webp";
import heroSectionGif from "../../Assets/Images/Home/heroSection.gif";
import focusAreaGif1 from "../../Assets/Images/Home/focusArea1.webp";
import focusAreaGif2 from "../../Assets/Images/Home/focusArea2.webp";
import focusAreaGif3 from "../../Assets/Images/Home/focusArea3.webp";
import focusAreaGif4 from "../../Assets/Images/Home/focusArea4.webp";
import growthSection1 from "../../Assets/Images/Home/growthSection1.webp";
import growthSection2 from "../../Assets/Images/Home/growthSection2.webp";
import growthSection3 from "../../Assets/Images/Home/growthSection3.webp";
import growthSection4 from "../../Assets/Images/Home/growthSection4.webp";
import selectedCases1 from "../../Assets/Images/Home/selectedCases1.webp";
import selectedCases2 from "../../Assets/Images/Home/selectedCases2.webp";
import selectedCases3 from "../../Assets/Images/Home/selectedCases3.webp";
import selectedCases4 from "../../Assets/Images/Home/selectedCases4.webp";
import selectedCases5 from "../../Assets/Images/Home/selectedCases5.webp";
import selectedCases6 from "../../Assets/Images/Home/selectedCases6.webp";
import selectedCases7 from "../../Assets/Images/Home/selectedCases7.webp";
import selectedCases8 from "../../Assets/Images/Home/selectedCases8.webp";
import selectedCases9 from "../../Assets/Images/Home/selectedCases9.webp";
import step1Gif from "../../Assets/Images/Home/workProcess1.webp";
import step2Gif from "../../Assets/Images/Home/workProcess2.webp";
import step3Gif from "../../Assets/Images/Home/workProcess3.webp";
import step4Gif from "../../Assets/Images/Home/workProcess4.webp";
import LoadingScreen from "../../Components/Molecules/Home/Loading";
import { useOutletContext } from "react-router-dom";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const HeroSection = lazy(
	() => import("../../Components/Organisms/Home/HeroSection"),
);
const FocusArea = lazy(
	() => import("../../Components/Organisms/Home/FocusArea"),
);
const GrowthSection = lazy(
	() => import("../../Components/Organisms/Home/GrowthSection"),
);
const SelectedCases = lazy(
	() => import("../../Components/Organisms/Home/SelectedCases"),
);
const WorkProcess = lazy(
	() => import("../../Components/Organisms/Home/WorkProcess"),
);
const ReputationSwiper = lazy(
	() => import("../../Components/Organisms/Home/ReputationSwiper"),
);
const FAQ = lazy(
	() => import("../../Components/Organisms/Home/FAQ"),
);

const preloadImage = (src) => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.src = src;
		img.onload = resolve;
		img.onerror = reject;
	});
};

gsap.registerPlugin(ScrollTrigger);

const HomePage = () => {
	const [isLoading, setIsLoading] = useOutletContext();
	const [heroSectionImage, setHeroSectionImage] = useState();

	const images = [
		focusAreaGif1,
		focusAreaGif2,
		focusAreaGif3,
		focusAreaGif4,
		growthSection1,
		growthSection2,
		growthSection3,
		growthSection4,
		selectedCases1,
		selectedCases2,
		selectedCases3,
		selectedCases4,
		selectedCases5,
		selectedCases6,
		selectedCases7,
		selectedCases8,
		selectedCases9,
		step1Gif,
		step2Gif,
		step3Gif,
		step4Gif,
	];

	useEffect(() => {
		const MIN_LOADING_TIME = 1500;
		const start = Date.now();
		if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
			setHeroSectionImage(heroSectionGif);
			images.unshift(heroSectionGif);
		} else {
			setHeroSectionImage(heroSectionWebP);
			images.unshift(heroSectionWebP);
		}
		Promise.all(images.map((src) => preloadImage(src)))
			.then(() => {
				const elapsedTime = Date.now() - start;
				const remainingTime = MIN_LOADING_TIME - elapsedTime;

				if (remainingTime > 0) {
					setTimeout(() => setIsLoading(false), remainingTime);
				} else {
					setIsLoading(false);
				}
			})
			.catch((error) => {
				console.error("Image preloading failed", error);
				setIsLoading(false);
			});


		console.log('Cleaning up GSAP');
		// Global GSAP and ScrollTrigger cleanup
		return () => {
			gsap.killTweensOf("*");
			ScrollTrigger.getAll().forEach(trigger => trigger.kill());
			ScrollTrigger.refresh();
		};
	}, []);

	return (
		<>
			{isLoading ? (
				<LoadingScreen />
			) : (
				<div>
					<HeroSection image={{ heroSectionImage }} />
					<FocusArea
						image={{
							focusAreaGif1,
							focusAreaGif2,
							focusAreaGif3,
							focusAreaGif4,
						}}
						gsapInstance={gsap}
					/>
					<GrowthSection
						image={{
							growthSection1,
							growthSection2,
							growthSection3,
							growthSection4,
						}}
						scrollTriggerInstance={ScrollTrigger}
					/>
					<SelectedCases
						image={{
							selectedCases1,
							selectedCases2,
							selectedCases3,
							selectedCases4,
							selectedCases5,
							selectedCases6,
							selectedCases7,
							selectedCases8,
							selectedCases9,
						}}
						gsapInstance={gsap}
					/>
					<WorkProcess
						image={{
							step1Gif,
							step2Gif,
							step3Gif,
							step4Gif,
						}}
					/>
					<ReputationSwiper />
					<FAQ />
				</div>
			)}
		</>
	);
};

export default HomePage;
