import { Section } from "./styles";
import snapshotGif from "../../../../Assets/Images/Services/snapshot.webp";

const Snapshot = () => {
	return (
		<Section>
			<img src={snapshotGif} alt="" />
		</Section>
	);
};

export default Snapshot;
