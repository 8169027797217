import { Outlet, useLocation } from "react-router-dom";
import DefaultNavbar from "./Navbar";
import { OutletContainer } from "./styles";
import DefaultFooter from "./Footer";
import React, {
	useEffect,
	useRef,
	useState
} from "react";
import Sidebar from "./Sidebar";
import {gsap} from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin)

const DefaultLayout = () => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const [isTablet, setIsTablet] = useState(window.innerWidth <= 992);
	const [isLoading, setIsLoading] = useState(true);
	const containerRef = useRef(null);
	const location = useLocation();

	const handleResize = () => {
		setIsTablet(window.innerWidth <= 992);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		const touchStartListener = () => {};
		document.addEventListener("touchstart", touchStartListener, { passive: true });
		return () => {
			document.removeEventListener("touchstart", touchStartListener);
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const enableScrolling = () => {
		containerRef.current.style.setProperty(
			"overflow-y",
			"auto",
			"important",
		);
		containerRef.current.style.setProperty(
			"position",
			"unset",
			"important",
		);
		containerRef.current.style.setProperty(
			"height",
			"auto",
			"important",
		);
		document.documentElement.style.overflow = "auto";
		document.body.style.overflowY = "auto";
	}
	useEffect(() => {
		if (containerRef.current) {
			if (location.pathname === "/" || isSidebarOpen) {
				if (isLoading || isSidebarOpen) {
					containerRef.current.style.setProperty(
						"overflow-y",
						"hidden",
						"important",
					);
					containerRef.current.style.setProperty(
						"position",
						"relative",
						"important",
					);
					containerRef.current.style.setProperty(
						"height",
						"100%",
						"important",
					);
					document.body.style.overflowY = "hidden";
					document.documentElement.style.overflowY = "hidden";
				}else {
					enableScrolling()
				}
			}else {
				enableScrolling();
			}
		}
	}, [isLoading, isSidebarOpen]);

	useEffect(() => {
		const timer = setTimeout(() => {
			gsap.to(window, { scrollTo: { y: 0, autoKill: false }, duration: 1 });

		}, 500);

		return () => clearTimeout(timer);
	}, [location]);

	return (
		<>
				<OutletContainer ref={containerRef}>
					<DefaultNavbar
						handleOpenMenu={() => setIsSidebarOpen(true)}
					/>
					<Outlet context={[isLoading, setIsLoading]} />
					<DefaultFooter />
					{isTablet && isSidebarOpen &&
						<Sidebar handleMenuClose={() => setIsSidebarOpen(false)} />
					}
				</OutletContainer>

		</>
	);
};

export default DefaultLayout;
