import {
	Section,
	SectionHeading,
	TextRow,
	RoundedShape,
	HeroButton,
	ShippedContainer,
	FirstTextRow,
	SecondTextRow,
	ThirdTextRow,
	ShippedProducts,
	CustomSwiperSlide,
} from "./styles";

import RoundedShapeImg from "../../../../Assets/Images/Services/rounded-shape.webp";
import shippedArrow from "../../../../Assets/Images/Culture/contact-us-arrow.svg";

import { useMobileDetector } from "../../../../utils/customHooks";
import { Autoplay, FreeMode } from "swiper/modules";
import { Swiper } from "swiper/react";

const HeroSection = () => {
	const ismobile = useMobileDetector();

	const handleScroll = () => {
		const element = document.getElementById("shareVisionSection");
		if (!element) return;
		window.scroll({
			top: element.offsetTop,
			behavior: "smooth",
		});
	};

	return (
		<Section>
			<SectionHeading>
				<FirstTextRow>
					{ismobile ? (
						<>
							<div>
								<TextRow>Comprehensive</TextRow>
							</div>
							<div>
								<TextRow>Digital</TextRow>
							</div>
						</>
					) : (
						<div>
							<TextRow>Comprehensive Digital</TextRow>
						</div>
					)}
				</FirstTextRow>

				<SecondTextRow>
					<div>
						<TextRow>Solutions to</TextRow>
					</div>
					<RoundedShape src={RoundedShapeImg} alt={"icon"} />
				</SecondTextRow>

				{ismobile ? (
					<ThirdTextRow>
						<div>
							<TextRow>Elevate Your</TextRow>
						</div>
						<div>
							<TextRow>Business</TextRow>
						</div>
					</ThirdTextRow>
				) : (
					<ThirdTextRow>
						<div>
							<TextRow>Elevate Your Business</TextRow>
						</div>
					</ThirdTextRow>
				)}

				<HeroButton onClick={handleScroll}>Book an appointment</HeroButton>
			</SectionHeading>
			<ShippedContainer>
				<span>
					Recently shipped products <img src={shippedArrow} alt="" />
				</span>
				<ShippedProducts>
					<Swiper
						loop={true}
						autoplay={{
							delay: 0,
							disableOnInteraction: false,
						}}
						effect={"fade"}
						speed={5000}
						modules={[FreeMode, Autoplay]}
						spaceBetween={40}
						slidesPerView={7}
						navigation
						className={"eachSlide swiper-no-swiping"}
					>
						<CustomSwiperSlide>
							<span>SaaS</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>Mobile App</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>Website Design</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>E-commerce Platform</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>Desktop App</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>Manual testing</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>CMS</span>
						</CustomSwiperSlide>

						{/* duplicate */}
						<CustomSwiperSlide>
							<span>SaaS</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>Mobile App</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>Website Design</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>E-commerce Platform</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>Desktop App</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>Manual testing</span>
						</CustomSwiperSlide>
						<CustomSwiperSlide>
							<span>CMS</span>
						</CustomSwiperSlide>
					</Swiper>
				</ShippedProducts>
			</ShippedContainer>
		</Section>
	);
};

export default HeroSection;
