import styled from "styled-components";

export const PopupResults = styled.div`
    margin-top: 120px;
    display: flex;
    justify-content: space-between;

    div:first-child {
        width: 16.7142857%;

        span {
            font-family: Inter, sans-serif;
            font-size: calc(10px + 0.625vw);
            font-weight: 400;
            line-height: calc(12px + 0.762vw);
            text-align: left;
            text-transform: uppercase;
            display: flex;
            gap: calc(10px + 0.625vw);
        }

        span::before {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            background-color: rgba(0, 0, 0, 1);
            border-radius: 50%;
        }
    }

    div:nth-child(2) {
        display: flex;
        flex-direction: column;

        span {
            font-family: Inter, sans-serif;
            font-size: calc(24px + 1.5vw);
            font-weight: 400;
            line-height: calc(32px + 1.631vw);
            text-align: left;
            color: rgba(0, 0, 0, 1);
            margin: 0;
        }

        div {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            align-content: flex-start;

            a {
                padding: calc(8px + 0.5vw) calc(20px + 1.25vw);
                display: flex;
                align-items: center;
                justify-content: center;

                gap: calc(8px + 0.5vw);
                border-radius: 90px;
                
                background-color: rgba(0, 0, 0, 1);
                margin-top: 44px;
                text-decoration: none;
  
                font-family: Inter, sans-serif;
                font-size: calc(14px + 0.875vw);
                font-weight: 500;
                line-height: calc(18px + 0.993vw);
                letter-spacing: 0.02em;
                text-align: left;
                color: rgba(255, 255, 255, 1);
				box-sizing: border-box;

                img {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        flex-direction: column;

        div:first-child {
            width: 100%;
        }

        div:nth-child(2) {
            width: 100%;
            margin-top: 0;
        }
    }

    @media (min-width: 480px) and (max-width: 640px) {
        div:nth-child(2) {
            & > a {
                padding: calc(6px + 0.5vw) calc(16px + 1.25vw);
                font-size: calc(11px + 0.875vw);
            }
        }
    }

    @media (max-width: 480px) {
		margin-top: 60px;
		
		div:first-child {
			span {
                font-size: calc(12px + 0.625vw);
                line-height: calc(14px + 0.762vw);
			}
			
			span:before {
				width: 14px;
				height: 14px;
			}
		}
		
        div:nth-child(2) {
            margin-top: 16px;
            gap: 24px;

            & > span {
				font-size: 20px;
				line-height: 24.2px;
            }
			
			& > div {
				margin-top: 4px;
                gap: 24px;
                width: 100%;
				
				span {
                    font-size: 20px;
                    line-height: 24.2px;
				}

                a {
                    width: 100%;
                    font-size: 12px;
                    line-height: 14.52px;
                    padding: 10.5px 22px;
                    gap: 10px;
                    margin: 0;
                    box-sizing: border-box;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
			}
        }
    }

    @media (min-width: 1600px) {
        div:first-child {
            width: 16.7142857%;

            span {
                font-size: 20px;
                line-height: 24.2px;
                gap: 20px;
            }
        }

        div:nth-child(2) {
            width: 83.2857143%;
			
            span {
                font-size: 48px;
                line-height: 58.09px;
            }

            a {
                padding: 16px 40px;
                gap: 16px;
                margin-top: 44px;

                font-size: 28px;
                line-height: 33.89px;
            }
        }
    }
`;
