import { LoadingContainer, LoadingWrapper } from "./styles";
import { useEffect } from "react";

import loading1Img from "../../../../Assets/Images/Loading/loading1.svg";
import loading2Img from "../../../../Assets/Images/Loading/loading2.svg";
import loading3Img from "../../../../Assets/Images/Loading/loading3.svg";
import loading4Img from "../../../../Assets/Images/Loading/loading4.svg";
import loading5Img from "../../../../Assets/Images/Loading/loading5.svg";


const letters = [loading1Img, loading2Img, loading3Img, loading1Img, loading4Img, loading5Img];

const LoadingScreen = () => {
	useEffect(() => {
		// disable scrolling on the page
		document.body.style.overflow = "hidden !important";
		const enableScroll = () => {
			document.body.style.overflow = "auto";
		};
		return enableScroll(); // re-enable scrolling on the page
	}, []);

	return (
		<LoadingWrapper>
			<LoadingContainer>
				{letters.map((letter, i) => (
					<img src={letter} key={i} alt={i} />
				))}
			</LoadingContainer>
		</LoadingWrapper>
	)
};

export default LoadingScreen;