import { useRef, useState } from "react";
import { useSendEmail } from "../../../../utils/customHooks";

import {
	Section,
	TextRow,
	SectionHeading,
	FormContainer,
	CollaborateContainer,
	ExpectationList,
	ContactContainer,
	Form,
	Input,
	TextArea,
	FormButton,
	NotificationBar,
	NotificationContainer,
} from "./styles";
import successIcon from "../../../../Assets/Images/NotificationBar/success.webp";
import warningIcon from "../../../../Assets/Images/NotificationBar/warning.webp";

const HeroSection = () => {
	const [formData, setFormData] = useState({
		fullName: "",
		email: "",
		tel: "",
		company: "",
		siteURL: "",
		message: "",
		fromContacts: true,
	});
	const { sendEmail, validationErrors, setValidationErrors, formRefs } =
		useSendEmail("https://server.spesna.com/api/contact/submit");
	const [notification, setNotification] = useState({
		message: "",
		type: "",
		visible: false,
	});
	const formRef = useRef(null);

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		// Clear the error for the specific field being modified
		setValidationErrors((prevErrors) => ({
			...prevErrors,
			[name]: "",
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		e.stopPropagation();
		const response = await sendEmail(formData);

		if (response) {
			if (response === "SUCCESS") {
				setNotification({
					message: "Email sent successfully!",
					type: "success",
					visible: true,
				});
				setFormData({
					fullName: "",
					email: "",
					tel: "",
					company: "",
					siteURL: "",
					message: "",
				});
			} else {
				setNotification({
					message: "Error sending email.",
					type: "warning",
					visible: true,
				});
			}
		} else {
			window.scrollTo({
				top: formRef.current.offsetTop - 130,
				behavior: "smooth",
			});
		}

		setTimeout(() => {
			setNotification((prev) => ({ ...prev, visible: false }));
		}, 2000);
	};

	return (
		<Section id="">
			{notification.visible && (
				<NotificationBar>
					{notification.type === "success" ? (
						<NotificationContainer success={1}>
							<img src={successIcon} alt="icon" />
							<span>{notification.message}</span>
						</NotificationContainer>
					) : (
						<NotificationContainer success={0}>
							<img src={warningIcon} alt="icon" />
							<span>{notification.message}</span>
						</NotificationContainer>
					)}
				</NotificationBar>
			)}
			<SectionHeading>
				<div>
					<TextRow>Hey! Tell us all</TextRow>
				</div>
				<div>
					<TextRow>the things</TextRow>
				</div>
			</SectionHeading>
			<FormContainer>
				<CollaborateContainer>
					<h2>Let’s Collaborate.</h2>
					<p>
						At Spesna Solutions, we’re excited to hear about your
						project. Share your vision with us, and together we’ll
						plan the next steps for a successful collaboration.
					</p>
					<h3>Here’s what you can expect:</h3>
					<ExpectationList>
						<span>→ We'll get back to you within 24 hours</span>
						<span>→ If needed, feel free to request an NDA</span>
						<span>
							→ Your will be talking with our tech project
							managers
						</span>
					</ExpectationList>
					<h4>Or</h4>
					<hr />
					<ContactContainer>
						<span>Call us +374 77 73 33 73</span>
						<span>Say hi contact@spesna.com</span>
					</ContactContainer>
				</CollaborateContainer>
				<Form ref={formRef} onSubmit={handleSubmit}>
					<div>
						<Input
							ref={formRefs.fullName}
							type={"text"}
							placeholder={"Name*"}
							name="fullName"
							onChange={handleChange}
							error={validationErrors.fullName ? 1 : 0}
						/>
						{validationErrors.fullName && (
							<p>{validationErrors.fullName}</p>
						)}
					</div>
					<div>
						<Input
							ref={formRefs.email}
							type={"text"}
							placeholder={"Email*"}
							name="email"
							onChange={handleChange}
							error={validationErrors.email ? 1 : 0}
						/>
						{validationErrors.email && (
							<p>{validationErrors.email}</p>
						)}
					</div>
					<div>
						<Input
							type="number"
							pattern="/^-?\d+\.?\d*$/"
							placeholder={"Phone Number"}
							name="tel"
							ref={formRefs.tel}
							onChange={handleChange}
						/>
					</div>
					<div>
						<Input
							ref={formRefs.company}
							type={"text"}
							placeholder={"Company"}
							name="company"
							onChange={handleChange}
						/>
					</div>
					<div>
						<Input
							ref={formRefs.siteURL}
							type={"text"}
							placeholder={"Website"}
							name="siteURL"
							onChange={handleChange}
							error={validationErrors.siteURL ? 1 : 0}
						/>
						{validationErrors.siteURL && (
							<p>{validationErrors.siteURL}</p>
						)}
					</div>
					<div>
						<TextArea
							ref={formRefs.message}
							placeholder={"Message*"}
							name="message"
							onChange={handleChange}
							error={validationErrors.message ? 1 : 0}
						/>
						{validationErrors.message && (
							<p>{validationErrors.message}</p>
						)}
					</div>
					<div>
						<FormButton type="submit">Send</FormButton>
					</div>
				</Form>
			</FormContainer>
		</Section>
	);
};

export default HeroSection;
