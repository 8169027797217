import styled from "styled-components";

export const PopupOutcomes = styled.div`
	width: 100%;
	margin-top: 120px;
	display: flex;
	flex-direction: column;
	gap: calc(20px + 2.5vw);

	& > span {
		font-family: Inter, sans-serif;
		font-size: calc(10px + 0.625vw);
		font-weight: 400;
		line-height: calc(12px + 0.762vw);
		text-align: left;
		text-transform: uppercase;
		display: flex;
		gap: calc(10px + 0.625vw);
	}

	& > span::before {
		content: "";
		display: inline-block;
		width: 20px;
		height: 20px;
		background-color: rgba(0, 0, 0, 1);
		border-radius: 50%;
	}
	
	@media (max-width: 480px) {
		margin-top: 60px;
		
		& > span {
            font-size: calc(12px + 0.625vw);
            line-height: calc(14px + 0.762vw);	
		}

        & > span::before {
            width: 16px;
            height: 16px;
        }
	}

	@media (min-width: 1600px) {
		gap: 60px;

		span {
			font-size: 20px;
			line-height: 24.2px;
			gap: 20px;
		}
	}
`;

export const OutcomesPercent = styled.div`
    width: 100%;
    display: flex;
    gap: calc(10px + 0.625vw);

    div {
        padding: calc(20px + 1.25vw);
        background-color: rgba(247, 247, 247, 1);
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    div:first-child {
        width: 66%;
    }

    div:nth-child(2) {
        width: 34%;
    }

    h4 {
        font-family: Inter, sans-serif;
        font-size: calc(24px + 6.761vw);
        font-weight: 500;
        line-height: calc(32px + 5.56vw);
        letter-spacing: -0.800000011920929px;
        text-align: left;
        margin-top: 0;
    }

    span {
        font-family: Inter, sans-serif;
        font-size: calc(10px + 0.625vw);
        font-weight: 400;
        line-height: calc(12px + 0.762vw);
        text-align: left;
        text-transform: lowercase;
    }

    @media (max-width: 480px) {
        div {
            border-radius: 20px;
        }

        h4 {
            font-size: calc(20px + 6.761vw);
            line-height: calc(26px + 5.56vw);
        }

        span {
            font-size: calc(6px + 0.625vw);
            line-height: calc(10px + 0.762vw);
        }
    }

    @media (max-width: 320px) {
        div {
            gap: 36px;
            width: 100%;
            padding: 20px 16px;

        }

        h4 {
            font-size: 32px;
            line-height: 38.73px;
            margin: 0;
        }

        span {
            font-size: 12px;
            line-height: 14.52px;
        }
    }

    @media (min-width: 1600px) {
        gap: 20px;

        div {
            padding: 40px;
        }

        h4 {
            font-size: 132.17px;
            line-height: 120.96px;
        }

        span {
            font-size: 20.44px;
            line-height: 29.57px;
        }
    }
`;
