import { Picture, PopupPictures } from "./styles";

const Pictures = ({ picturesObj }) => {
	return (
		<PopupPictures>
			{picturesObj.map((picture, index) => {
				return (
					<Picture key={index} type={picture.type}>
						<img src={require(`../../../../../Assets/Images/Popup/${picture.src}`)} alt="" />
					</Picture>
				);
			})}
		</PopupPictures>
	);
};

export default Pictures;
