import { OutcomesPercent, PopupOutcomes } from "./styles";

const Outcomes = () => {
	return (
		<PopupOutcomes>
			<span>outcomes in NUMBERS</span>
			<OutcomesPercent>
				<div>
					<h4>30+</h4>
					<span>completed projects</span>
				</div>
				<div>
					<h4>3</h4>
					<span>years of experience</span>
				</div>
			</OutcomesPercent>
		</PopupOutcomes>
	);
};

export default Outcomes;