import styled, { keyframes } from "styled-components";

export const Section = styled.section`
    flex: 1;
    width: 100%;
    height: 100vh;
	min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 60px;
    max-width: 1250px;
    margin: 0 auto;
    position: relative;
    bottom: env(safe-area-inset-bottom);
    //min-height: -webkit-fill-available;
    top: 0;
`;

const RevealText = keyframes`
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
`

export const SectionHeading = styled.h1`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    white-space: nowrap;
    margin: 127px auto 0;
	width: 100%;
	
    & > div {
        overflow: hidden;
    }

    & > div > span {
        z-index: 1;
    }
	
	& > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
		
		span {
            animation-delay: 0s;
		}
	}
	
    & > div:nth-child(2) {
        margin: calc(-10px - 0.625vw) 0 0 calc(19px + 28.125vw);
        z-index: -2;

        span {
            animation-delay: 0.5s;
        }
    }

    & > div:nth-child(3) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: calc(-10px - 0.625vw) 0 0 calc(20px + 3.75vw);

        span {
            animation-delay: 1s;
        }
    }
	
	//@media (max-width: 992px) {
	//	margin-top: 84px;
	//}

    @media (min-width: 768px) and (max-width: 992px) {
        & > div:nth-child(2) {
            margin: calc(-10px - 0.625vw) 0 0 calc(19px + 20.125vw);
        }
    }
	
	@media (max-width: 768px) {
        & > div:first-child {
            width: 100%;
            justify-content: flex-start;
            margin-left: calc(13px + 8.333vw);
			
            span {
                animation-delay: 0s;
            }
        }

        & > div:nth-child(2) {
			width: 100%;
			display: flex;
			justify-content: center;
			margin: calc(-10px - 0.625vw) 0 0;
			
            span {
                animation-delay: 0.25s;
            }
        }

        & > div:nth-child(3) {
			width: 100%;
			margin-left: 0;
			
            span {
                animation-delay: 0.5s;
            }
        }

        & > div:nth-child(4) {
			width: 100%;
			
            span {
                animation-delay: 0.75s;
            }
        }

        & > div:nth-child(5) {
            span {
                animation-delay: 1s;
            }
        }
	}
	
	@media (min-width: 480px) and (max-width: 992px) {
		justify-content: center;
		align-items: center;
    }
`;

export const TextRow = styled.span`
    display: block;
    transform: translate(0, 100%);
    animation: ${RevealText} 1.5s cubic-bezier(0.77, 0, 0.175, 1) 0.5s;
    animation-fill-mode: forwards !important;
    font-size: calc(24px + 4.5vw);
    font-weight: 500;
    line-height: calc(32px + 5.261vw);
    text-align: left;

    @media (min-width: 320px) and (max-width: 480px) {
        font-size: calc(18px + 4.5vw);
        line-height: calc(26px + 5.261vw);
    }

    @media (max-width: 320px) {
        font-size: 32px;
        line-height: 38.73px;
        margin-top: 0 !important;
    }

    @media (min-width: 1600px) {
        font-size: 96px;
        line-height: 116.18px;
		
		&:first-child {
            margin-left: 0;
		}
        &:nth-child(2) {
            margin: -20px 0 0 0;
        }

        &:nth-child(3) {
            margin: -20px 0 0 120px;
        }
    }
`;

const RevealShape = keyframes`
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
`

const BounceShape = (size) => keyframes`
	0% {
		transform: translateY(${size});
	}
	50% {
		transform: translateY(0);
	}
	100% {
        transform: translateY(${size});
	}
`

export const RoundedShape = styled.img`
    position: absolute;
    width: calc(175px + 50vw);
    height: calc(100px + 50vw);
	margin: calc(-38px - 9.5vw) 0 0 calc(-35px - 8.75vw);
    opacity: 0;
    animation-delay: 2s;
    animation: ${RevealShape} 2s cubic-bezier(0.77, 0, 0.175, 1) 0.5s, ${BounceShape('calc(-5px - 1.563vw)')} 5s linear infinite;
    animation-fill-mode: forwards;
    z-index: 0;
	
	@media (max-width: 480px) {
		width: calc(209px + 43.542vw);
		height: auto;
        margin: calc(-24px - 9.5vw) 0 0 calc(-54px - 8.75vw);
    }
	
    @media (min-width: 1600px) {
        width: 975px;
        height: 900px;
        margin: -190px 0 0 -175px;
        animation: ${RevealShape} 2s cubic-bezier(0.77, 0, 0.175, 1) 0.5s, ${BounceShape('-30px')} 2s linear infinite;
        animation-fill-mode: forwards;
    }
`;

export const HeroButton = styled.button`
	cursor: pointer;
	margin-top: 3.75vw;
	padding: 16px 28px;
	border-radius: 90px;
	background: #000000;
	font-size: calc(10px + 1.125vw);
	font-weight: 500;
	line-height: calc(10px + 1.493vw);
	letter-spacing: 0.02em;
	color: #ffffff;
	z-index: 3;
	border: none;

	@media (max-width: 480px) {
		width: 100%;
		margin-top: 60px;
	}

	@media (min-width: 1600px) {
		font-size: 28px;
		line-height: 33.89px;
		margin-top: 80px;
	}
`;
