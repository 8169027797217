import styled from "styled-components";

export const Section = styled.section`
	width: 100%;
	left: 0;
	right: 0;
	display: flex;
	margin: 60px auto 0;
	position: relative;
	max-width: 1400px;

	img {
		width: 100%;
	}
	
	@media (max-width: 768px) {
		margin: 0 auto;
	}
`;
