import HeroSection from "../../Components/Organisms/ContactUs/HeroSection";
import ContactsSection from "../../Components/Organisms/ContactUs/ContactsSection";
import { useEffect } from "react";

const ContactUsPage = () => {
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		window.scrollTo({
	// 			top: 0,
	// 			behavior: "smooth"
	// 		});
	// 	}, 100);
	// }, []);

	return (
		<div>
			<HeroSection />
			<ContactsSection />
		</div>
	);
};

export default ContactUsPage;
