import { OutcomesPercent, PopupOutcomes } from "./styles";

const Outcomes = ({ outcomesObj }) => {
	return (
		<PopupOutcomes>
			<span>outcomes in NUMBERS</span>
			<OutcomesPercent>
				<div>
					<h4>{outcomesObj.percent1}</h4>
					<span>{outcomesObj.percent1Description}</span>
				</div>
				<div>
					<h4>{outcomesObj.percent2}</h4>
					<span>{outcomesObj.percent2Description}</span>
				</div>
			</OutcomesPercent>
		</PopupOutcomes>
	);
};

export default Outcomes;
