import {
	Section,
	SectionHeading,
	TextRow,
	RoundedShape,
	HeroButton,
} from "./styles";

import roundedShapeImg from "../../../../Assets/Images/Work/roundedShape.webp";

import { useMobileDetector } from "../../../../utils/customHooks";

const HeroSection = () => {
	const ismobile = useMobileDetector();

	const handleScroll = () => {
		const element = document.getElementById("shareVisionSection");
		if (!element) return;
		window.scroll({
			top: element.offsetTop,
			behavior: "smooth",
		});
	};

	return (
		<Section>
			<SectionHeading>
				{ismobile ? (
					<>
						<div>
							<TextRow>
								Creating{" "}
							</TextRow>
							<RoundedShape src={roundedShapeImg} alt="" />
						</div>

						<div>
							<TextRow>impactful</TextRow>
						</div>

						<div>
							<TextRow>digital</TextRow>
						</div>

						<div>
							<TextRow>solutions for</TextRow>
						</div>

						<div>
							<TextRow>top businesses</TextRow>
						</div>
					</>
				) : (
					<>
						<div>
							<TextRow>
								Creating impactful{" "}
							</TextRow>
							<RoundedShape src={roundedShapeImg} alt="" />
						</div>
						<div>
							<TextRow>digital solutions</TextRow>
						</div>
						<div>
							<TextRow>for top businesses</TextRow>
						</div>
					</>
				)}

				<HeroButton onClick={handleScroll}>Book an appointment</HeroButton>
			</SectionHeading>
		</Section>
	);
};

export default HeroSection;
